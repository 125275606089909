<template lang="pug">
  .candidates-sidebar-filters(:class="{'candidates-sidebar-filters_active':showing}")
    v-card.filters-wrapper__card
      .filters-top.d-flex.align-center
        h3.filters-top__title Filters
        v-spacer
        v-btn(outlined color="primary" data-test='candidates-filters-close' @click="emitToggle").btn-common.btn-commmon_bold
          v-icon(left) mdi-close
          | Close

      v-expansion-panels(:multiple="true" :value="[0, 1]")
        v-expansion-panel.filters-pannel.expansion-panel
          v-expansion-panel-header(data-test="candidates-filters-details").form-title By Candidate Details
          v-expansion-panel-content.expansion-panel__content
            autocomplete-filter(
              v-model="sidebarFilters.cbrStatus"
              :model="filtersModel"
              field="cbrStatus"
              label="CBR status"
              data-test="filter-cbrstatus"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            autocomplete-filter(
              v-model="sidebarFilters.courseCity"
              :model="filtersModel"
              item-value="ID"
              item-text="name"
              field="courseCities"
              label="Course location"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            datepicker(
              v-model="sidebarFilters.courseDateAfter"
              label="Course date from"
            )
            datepicker(
              v-model="sidebarFilters.courseDateBefore"
              label="Course date to"
            )
            autocomplete-filter(
              v-model="sidebarFilters.cbrUser"
              item-text="username"
              item-value="ID"
              :model="filtersModel"
              field="cbrUsers"
              label="CBR user"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            autocomplete-filter(
              v-model="sidebarFilters.licenseType"
              :model="filtersModel"
              field="licenseTypes"
              label="License type"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            autocomplete-filter(
              v-model="sidebarFilters.examProduct"
              :model="filtersModel"
              field="examProducts"
              label="Product name"
              itemText="name"
              itemValue="name"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            autocomplete-filter(
              v-model="sidebarFilters.fixedDates"
              :model="filtersModel"
              field="fixedDates"
              label="Fixed dates"
              showCloseMenuBtn
              closeOnInputClick
              itemText="label"
              itemValue="value"
            )
            v-text-field(
              v-model="sidebarFilters.lastError"
              label="Last error"
            )

        v-expansion-panel.expansion-panel
          v-expansion-panel-header.form-title By Exam Reservation
          v-expansion-panel-content.expansion-panel__content
            autocomplete-filter(
              v-model="sidebarFilters.queueExamLocation"
              :model="filtersModel"
              field="queueExamLocation"
              label="Queue exam location"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            datepicker(
              v-model="sidebarFilters.queueExamDateAfter"
              label="Queue date from"
            )
            datepicker(
              v-model="sidebarFilters.queueExamDateBefore"
              label="Queue date to"
            )
            v-checkbox(
              v-model="sidebarFilters.queueExamDateStrict"
              label="Queue date strict"
              hide-details
            )
            datepicker(
              v-model="sidebarFilters.queueTimeAfter"
              label="Queue time from"
              icon='mdi-clock-outline'
              convertToDefault=false
              timepicker
            )
            datepicker(
              v-model="sidebarFilters.queueTimeBefore"
              label="Queue time to"
              icon='mdi-clock-outline'
              convertToDefault=false
              timepicker
            )
            appComboBox(
              @input="(val) => sidebarFilters.examRequestsCount = val"
              :items="examRequestsItems"
              :value="sidebarFilters.examRequestsCount"
              label="Exam requests count"
              name="examRequest"
            )
            autocomplete-filter(
              v-model="sidebarFilters.city"
              :model="filtersModel"
              field="cities"
              label="Exam location"
              multiple
              showCloseMenuBtn
              closeOnInputClick
            )
            datepicker(
              v-model="sidebarFilters.examDateAfter"
              label="Exam date from"
            )
            datepicker(
              v-model="sidebarFilters.examDateBefore"
              label="Exam date to"
            )
            datepicker(
              v-model="sidebarFilters.examTimeAfter"
              label="Exam time from"
              icon='mdi-clock-outline'
              convertToDefault=false
              timepicker
            )
            datepicker(
              v-model="sidebarFilters.examTimeBefore"
              label="Exam time to"
              icon='mdi-clock-outline'
              convertToDefault=false
              timepicker
            )

    .filters-actions
      v-spacer
      v-btn(outlined color="primary" @click="emitToggle" data-test='filters-cancel').btn-common.btn-commmon_bold.filters-actions__cancel
        | cancel
      v-btn(color="primary" @click="applyFilters" data-test='filters-submit').text-capitalize
        v-icon(left) mdi-magnify
        | Apply
</template>

<script>
export default {
  name: 'candidatesSidebarFilters',

  props: {
    filtersModel: {
      type: Object,
      required: true
    },
    showing: Boolean
  },

  data: () => ({
    examRequestsItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

    sidebarFilters: {
      cbrStatus: [],
      courseCity: [],
      courseDateAfter: '',
      courseDateBefore: '',
      cbrUser: [],
      examProduct: [],
      licenseType: [],
      fixedDates: undefined,
      queueExamLocation: [],
      queueExamDateAfter: '',
      queueExamDateBefore: '',
      queueExamDateStrict: false,
      queueTimeAfter: '',
      queueTimeBefore: '',
      examRequestsCount: undefined,
      city: [],
      examDateBefore: '',
      examDateAfter: '',
      examTimeBefore: '',
      examTimeAfter: '',
      lastError: '',
    }
  }),

  methods: {
    emitToggle() {
      this.$emit('click:toggle')
    },

    applyFilters() {
      this.$emit('applyFilters', this.sidebarFilters)
    },

    initSidebarFilters() {
      for (let filter in this.sidebarFilters) {
        if (filter in this.filtersModel.filters) {
          this.sidebarFilters[filter] = this.filtersModel.filters[filter]
        }
      }
    }
  },

  watch: {
    'showing'() {
      if (this.showing) this.initSidebarFilters()
    }
  },

  components: {
    appComboBox: () => import('@/components/global/fields/appComboBox.vue'),
    autocompleteFilter: () => import('../../filters/CandidateAutocompleteFilter.vue'),
    datepicker: () => import('../../filters/CandidateDatepickerFilters.vue'),
  }
}
</script>

<style lang="scss">
.candidates-sidebar-filters {
  position: relative;
}

.filters-wrapper {
  &__card {
    height: calc(100vh - 136px);
    overflow-y: auto;
    padding-bottom: 70px;
  }
}

.filters-top {
  padding: 14px;
  border-bottom: 1px solid $border-color;

  &__title {
    font-size: 18px;
    font-weight: normal;
  }
}

.v-expansion-panel::before, .v-expansion-panel::after {
  display: none;
}

.v-expansion-panel-header {
  padding: 14px;
}

.filters-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px;
  display: flex;
  z-index: 1;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid $border-color;

  &__cancel {
    margin-right: 14px;
  }
}
</style>
